/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css"
import { withTranslation } from "react-i18next";
import PageContent, { PAGE_CONTENT_TYPE_CAPTURE } from "Components/PageContent";
import imgsuccess from "../../../assets/success-icon.png";
import imgwarning from "../../../assets/warning-icon.png"
import { Modal } from "react-bootstrap";
import { applicationCreate, applicationContinue } from "logic/actions/api/application";
import { generateUserID } from "logic/userId";
import { typeDecryptParams } from "logic/typeDecryptParams";
import { setApplicationParams } from "logic/actions/application";
import { USER } from 'logic/actions/application';
import { logEvent } from "logic/eventLogger";
import { go } from 'logic/actions/navigation';
import { INTRO_CONFIRM_TO_START } from 'logic/enums/pages';
// import { store } from "logic/store";

export class PageSignedUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalConfirm: false,
            pending: false,
            error: ""
        }
        this.confirmCreate.bind(this);
    }

    confirmCreate = () => {
        this.setState({
            pending: true,
            modalConfirm: false
        })
        const parameters = new URLSearchParams(window.location.search);
        const pathname = window.location.pathname.split('/')[1]
        const start = parameters.get("start");
        const flag = parameters.get('flag')?.toLocaleUpperCase()
        if (start && pathname) {
            let resultParam = typeDecryptParams(flag, start)
            let userInfo = {
                userId: generateUserID(resultParam.componentID, resultParam.referenceID)
            }
            this.props.applicationCreate(userInfo)
                .then((resA) => {
                    let isParams = {
                        userOfUse: USER,
                        pathname: pathname,
                        componentID: resultParam.componentID,
                        referenceID: resultParam.referenceID,
                        nationality: resultParam.nationality?.trim().toLowerCase() || "undefined",
                        createDate: resultParam.createDate,
                        urlStart: window.location.href,
                        secondDevice: resultParam.second ? resultParam.second : "undefined",
                        callbackURL: resultParam.callbackURL ? resultParam.callbackURL : "undefined",
                        showValid: resultParam.show ? resultParam.show.toLowerCase().split(',') : "undefined",
                        insureAge : resultParam.insureAge?.trim() || 'undefined',
                        risk: resultParam.risk?.trim().toLowerCase() || 'medium',
                        salesChannel: resultParam.salesChannel?.toLowerCase() || 'non-f2f',
                        occupation: resultParam.occupation?.trim() || 'undefined',
                        allowRetryOtherDoc: resultParam.allowRetryOtherDoc?.trim().toLowerCase() || 'undefined',
                        hideSuccessPage: resultParam.hideSuccessPage?.trim().toLowerCase() || 'undefined',
                        requiredField: {
                          idCard: resultParam.idCard?.trim() || 'undefined',
                          firstName: resultParam.firstName?.trim() || 'undefined',
                          lastName: resultParam.lastName?.trim() || 'undefined',
                          dateOfBirth: resultParam.dateOfBirth?.trim() || 'undefined',
                          gender: resultParam.gender?.trim() || 'undefined',
                        }
                    }
                    logEvent("Application_create-success[signed]", {
                        pathname: pathname,
                        response: resA,
                        params: isParams
                    })
                    this.props.setApplicationParams(isParams)
                    this.props.applicationContinue({ applicationId: userInfo.userId })
                        .then((resB) => {
                            this.setState({
                                pending: false
                            })
                            logEvent("Application_continue-success[signed]", {
                                pathname: pathname,
                                response: resB,
                                params: isParams
                            })
                            this.props.go(INTRO_CONFIRM_TO_START)
                        })
                        .catch((err) => {
                            this.setState({
                                pending: false,
                                error: err.toString()
                            })
                            logEvent("Application_continue-fail[signed]", {
                                pathname: pathname,
                                response: err,
                                params: isParams
                            })
                        })
                })
                .catch((err) => {
                    logEvent("Application_create-fail[signed]", {
                        pathname: pathname,
                        response: err
                    })
                    this.setState({
                        pending: false,
                        error: err.toString()
                    })
                })
        } else {
            this.setState({
                pending: false,
                error: "error create!!!"
            })
        }

    }

    render() {
        return (
            <PageContent toggleLoading={this.state.pending} progressBar={false} type={this.state.pending ? PAGE_CONTENT_TYPE_CAPTURE : ""} isSpinner={true}>
                <div className="container-fluid">
                    <div className="card-body center">
                        <img
                            src={imgsuccess}
                            alt="thankyou"
                            className="mb-3"
                        />
                        <div className="title-text mt-4 mb-2">
                            <h2>{this.props.t("PageSignedUp.title_text")}</h2>
                            <a className="w-full" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageSignedUp.title_text_main')}</a>
                        </div>
                        <div className="row row-justify-center">
                            <button
                                type="button"
                                onClick={() => this.setState({ modalConfirm: true })}
                                className="btn btn-lg"
                                style={{ color: "white", backgroundColor: "#ADADAD", fontSize: '1em' }}
                            >{this.props.t('PageSignedUp.btn_start_confirm')}</button>
                        </div>
                    </div>
                    <div className="row row-justify-center">
                        <strong className="message-box-error">{this.state.error}</strong>
                    </div>
                </div>
                <Modal
                    show={this.state.modalConfirm}
                    size="lg"
                    // onHide={() => this.setState({ onModal: false })}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '30%', height: 'inherit' }}
                                src={imgwarning}
                                alt="warning"
                            />
                        </div>
                        <div className="row row-justify-center center mb-4">
                            <h2 className="mt-3"><b>{this.props.t("PageSignedUp.alert_header_title")}</b></h2>
                            <p className="mt-1 w-full" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageSignedUp.alert_text_title')}</p>
                        </div>
                        <div className="row center">
                            <div className="col-6">
                                <button
                                    type="button"
                                    className="btn btn-lg"
                                    style={{ backgroundColor: "#D31145", fontSize: '1em', width: '100%' }}
                                    onClick={this.confirmCreate}
                                >{this.props.t("PageSignedUp.btn_confirm")}</button>
                            </div>
                            <div className="col-6">
                                <button
                                    type="button"
                                    className="btn btn-lg"
                                    style={{ backgroundColor: "#D31145", fontSize: '1em', width: '100%' }}
                                    onClick={() => this.setState({ modalConfirm: false })}
                                >{this.props.t("PageSignedUp.btn_cancel")}</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </PageContent>
        )
    }
}

const componentWithTranslation = withTranslation()(PageSignedUp);
export default connect(
    (state) => {
        return {
            ...state.application,
            setApplicationParams: state.setApplicationParams,
            language: state.changeMultiLanguage
        }
    },
    {
        applicationContinue,
        applicationCreate,
        setApplicationParams,
        go
    }
)(componentWithTranslation);